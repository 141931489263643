import { Injectable } from '@angular/core';
import {
  LimitReportPosition,
  LimitReportPositionPrice,
} from '@backoffice-frontend/limit-report/domain';
import { DataTableParameters } from '@clean-code/shared/common';
import { FormBaseService } from '@clean-code/shared/util/util-component-services';
import { ID, PaginationResponse } from '@datorama/akita';
import { Observable } from 'rxjs';
import { LimitReportPositionPowerService } from './limit-report-position.service';

@Injectable({
  providedIn: 'root',
})
export class LimitReportPositionFormFacade extends FormBaseService<LimitReportPosition> {
  constructor(protected override service: LimitReportPositionPowerService) {
    super(service);
  }

  getAll$(
    _params: DataTableParameters,
  ): Observable<PaginationResponse<LimitReportPosition>> {
    throw new Error('Method not implemented.');
  }

  public getLimitReportPowerPositionPrice(
    limitReportId: ID,
    tradeDate: Date,
  ): Observable<LimitReportPositionPrice> {
    return this.service.getLimitReportPowerPositionPrice(
      limitReportId,
      tradeDate,
    );
  }

  public limitReportPowerPositionLastTradeDatePrice$(
    limitReportId: ID,
  ): Observable<LimitReportPositionPrice> {
    return this.service.limitReportPowerPositionLastTradeDatePrice$(
      limitReportId,
    );
  }
}
