import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import {
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {
    BuyingProcess,
    LimitReportPosition,
    LimitReportPositionPrice,
    LimitReportStore,
    LimitStrategy,
} from '@backoffice-frontend/limit-report/domain';
import { EnumService } from '@backoffice-frontend/shared/bo/util-masterdata';
import { VarDirective } from '@backoffice-frontend/shared/directives/common';
import { TradeType } from '@clean-code/shared/bo/util-enum';
import { ID, indicate } from '@clean-code/shared/common';
import { CanDeactivateForm } from '@clean-code/shared/components/ui-can-deactivate';
import { FormContainerModule } from '@clean-code/shared/components/ui-form-container';
import { UtilDateModule } from '@clean-code/shared/util/util-date';
import { TranslocoModule } from '@jsverse/transloco';
import { NgSelectModule } from '@ng-select/ng-select';
import { BuyingProcessDropdownComponent } from 'limit-report/ui-buying-process-dropdown';
import { Subject, combineLatest, merge } from 'rxjs';
import {
    debounceTime,
    filter,
    first,
    map,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs/operators';
import { LimitReportPositionFormFacade } from '../../services/limit-report-position-form.facade';

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'backoffice-frontend-limit-report-position-power-form ',
  templateUrl: './limit-report-position-form.component.html',
  imports: [
    CommonModule,

    MatFormFieldModule,
    MatSlideToggleModule,
    NgSelectModule,

    TranslocoModule,

    FormContainerModule,
    BuyingProcessDropdownComponent,
    VarDirective,
    UtilDateModule
  ],
})
export class LimitReportPositionPowerFormComponent
  extends CanDeactivateForm<LimitReportPosition>
  implements OnInit, OnDestroy
{
  public override idKeyName = 'positionId';
  private parentIdKeyName = 'id';
  override sendRawData = true;

  public baseFactor = 0.0;
  public peakFactor = 0.0;

  #limitReportStore = inject(LimitReportStore);
  contractId = this.#limitReportStore.contractId;

  formGroup = new UntypedFormGroup({
    id: new UntypedFormControl(null),
    designation: new UntypedFormControl({ value: null, disabled: true }, [
      Validators.required,
    ]),
    tradeDate: new UntypedFormControl(null, [Validators.required]),
    amount: new UntypedFormControl(null, [Validators.required]),
    price: new UntypedFormControl(null, [Validators.required]),
    basePrice: new UntypedFormControl(null, [Validators.required]),
    peakPrice: new UntypedFormControl(null, [Validators.required]),
    surcharge: new UntypedFormControl({ value: null, disabled: true }, [
      Validators.required,
    ]),
    limitStrategy: new UntypedFormControl(LimitStrategy.Passive, [
      Validators.required,
    ]),
    limitReportId: new UntypedFormControl(null, [Validators.required]),
    buyingProcess: new UntypedFormControl(BuyingProcess.Completed, [
      Validators.required,
    ]),
    tradeType: new UntypedFormControl(TradeType.buy, [Validators.required]),
  });

  public limitStrategies$ = this.enumService.getEnumType$('LimitStrategy');
  public tradeTypes$ = this.enumService.getEnumType$('TradeType');
  public dateChange$ = new Subject<Date>();

  private positionFaktor$ = combineLatest([this.dateChange$, this.isNew$]).pipe(
    filter(([, isNew]: [Date, boolean]) => !isNew),
    map(([date]: [Date, boolean]) => date),
    switchMap((date: Date) =>
      this.service
        .getLimitReportPowerPositionPrice(
          this.formGroup.controls.limitReportId.value,
          date,
        )
        .pipe(indicate(this.isLoading$)),
    ),
    tap((value: LimitReportPositionPrice) => {
      this.baseFactor = value.baseFactor;
      this.peakFactor = value.peakFactor;

      this.formGroup.controls.surcharge.setValue(value.surcharge);
    }),
    takeUntil(this.closeSubject),
  );

  private positionPrice$ = this.dateChange$.pipe(
    switchMap((date: Date) =>
      this.service
        .getLimitReportPowerPositionPrice(
          this.formGroup.controls.limitReportId.value,
          date,
        )
        .pipe(indicate(this.isLoading$)),
    ),
    tap((value: LimitReportPositionPrice) => {
      this.baseFactor = value.baseFactor;
      this.peakFactor = value.peakFactor;

      this.formGroup.controls.surcharge.setValue(value.surcharge);
      this.formGroup.controls.basePrice.setValue(value.basePrice);
      this.formGroup.controls.peakPrice.setValue(value.peakPrice);
    }),
    takeUntil(this.closeSubject),
  );

  private ids$ = this.activatedRoute.paramMap.pipe(
    map((params) => {
      const parentId: ID = params.get(this.parentIdKeyName);
      const id: ID = params.get(this.idKeyName);
      return [parentId, id];
    }),
  );

  private initializePrice$ = this.ids$.pipe(
    filter(([, id]) => !!id && id === 'new'),
    switchMap(([parentId]) =>
      this.service
        .limitReportPowerPositionLastTradeDatePrice$(parentId)
        .pipe(indicate(this.isLoading$)),
    ),
    tap((value: LimitReportPositionPrice) => {
      this.baseFactor = value.baseFactor;
      this.peakFactor = value.peakFactor;

      this.formGroup.controls.tradeDate.setValue(value.tradeDate);
      this.formGroup.controls.amount.setValue(value.amount);

      this.formGroup.controls.surcharge.setValue(value.surcharge);
      this.formGroup.controls.basePrice.setValue(value.basePrice);
      this.formGroup.controls.peakPrice.setValue(value.peakPrice);

      this.formGroup.controls.designation.setValue(value.designation);
    }),
    takeUntil(this.closeSubject),
  );

  private priceChanges$ = merge(
    this.formGroup.controls.basePrice.valueChanges,
    this.formGroup.controls.peakPrice.valueChanges,
  ).pipe(
    debounceTime(200),
    tap(() => {
      this.formGroup.controls.price.setValue(
        Math.round(
          (this.formGroup.controls.basePrice.value * this.baseFactor +
            this.formGroup.controls.peakPrice.value * this.peakFactor +
            this.formGroup.controls.surcharge.value +
            Number.EPSILON) *
            100,
        ) / 100,
      );
    }),
    takeUntil(this.closeSubject),
  );

  constructor(
    protected override service: LimitReportPositionFormFacade,
    public enumService: EnumService,
  ) {
    super(service);
  }

  ngOnInit(): void {
    this.initializePrice$.pipe(takeUntil(this.closeSubject)).subscribe();
    this.priceChanges$.pipe(takeUntil(this.closeSubject)).subscribe();
    this.positionPrice$.pipe(takeUntil(this.closeSubject)).subscribe();
    this.positionFaktor$.pipe(takeUntil(this.closeSubject)).subscribe();

    this.ids$
      .pipe(
        first(),
        tap(([parentId]) => {
          //yes, this will be set by patchFormGroupValues, but other observables are depending on it before
          this.formGroup.controls.limitReportId.setValue(parentId);
        }),
        takeUntil(this.closeSubject),
      )
      .subscribe();
  }
}
