<cc-form-container
  [isLoading]="isLoading$ | async"
  *ngVar="id$ | async; let id"
  >
  <ng-container form-buttons>
    <cc-save-action-button
      class="button-align"
      (click)="submit()"
    ></cc-save-action-button>
    @if (!contractId()) {
      <cc-delete-action-button
        (onClick)="delete(id)"
      ></cc-delete-action-button>
    }
  </ng-container>

  <form
    [formGroup]="formGroup"
    [patchFormGroupValues]="value$ | async"
    form-content
    >
    <div formContainerFormRow>
      <mat-form-field>
        <mat-label>{{
          'limit-reports.position.fields.DESIGNATION' | transloco
        }}</mat-label>
        <input
          matInput
          placeholder="{{
            'limit-reports.position.fields.DESIGNATION' | transloco
          }}"
          formControlName="designation"
          />
        </mat-form-field>
        <bo-buying-process-dropdown
          formControlName="buyingProcess"
        ></bo-buying-process-dropdown>
      </div>

      <div formContainerFormRow>
        <mat-form-field>
          <mat-label>{{
            'limit-reports.position.fields.AMOUNT' | transloco
          }}</mat-label>
          <input
            matInput
            placeholder="{{ 'limit-reports.position.fields.AMOUNT' | transloco }}"
            formControlName="amount"
            type="number"
            />
          </mat-form-field>
          <ng-select
            [items]="limitStrategies$ | async"
            bindLabel="name"
            bindValue="name"
            [multiple]="false"
            appendTo="body"
            [clearable]="false"
            formControlName="limitStrategy"
            [searchable]="false"
        placeholder="{{
          'limit-reports.position.fields.LIMIT_STRATEGY' | transloco
        }}"
            required
            >
            <ng-template ng-label-tmp let-item="item">
              <div>
                {{ 'limit-reports.common.limit-strategy.' + item.name | transloco }}
              </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
              <div>
                {{ 'limit-reports.common.limit-strategy.' + item.name | transloco }}
              </div>
            </ng-template>
          </ng-select>
        </div>

        <div formContainerFormRow>
          <ng-select
            [items]="tradeTypes$ | async"
            bindLabel="name"
            bindValue="name"
            [multiple]="false"
            appendTo="body"
            [clearable]="false"
            formControlName="tradeType"
            [searchable]="false"
        placeholder="{{
          'limit-reports.position.fields.TRADE_TYPE' | transloco
        }}"
            required
            >
            <ng-template ng-label-tmp let-item="item">
              <div>{{ 'bo.common.trade-type.' + item.name | transloco }}</div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
              <div>{{ 'bo.common.trade-type.' + item.name | transloco }}</div>
            </ng-template>
          </ng-select>
          <mat-form-field>
            <mat-label>{{
              'limit-reports.position.fields.DATE' | transloco
            }}</mat-label>
            <input
              matInput
              [matDatepicker]="date"
              placeholder="{{ 'limit-reports.position.fields.DATE' | transloco }}"
              formControlName="tradeDate"
              />
              <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
              <mat-datepicker #date></mat-datepicker>
            </mat-form-field>
          </div>

          <div formContainerFormRow>
            <mat-form-field>
              <mat-label>{{
                'limit-reports.position.fields.PRICE' | transloco
              }}</mat-label>
              <input
                matInput
                placeholder="{{ 'limit-reports.position.fields.PRICE' | transloco }}"
                formControlName="price"
                readonly
                type="number"
                />
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{
                  'limit-reports.position.fields.BASE_PRICE' | transloco
                }}</mat-label>
                <input
                  matInput
          placeholder="{{
            'limit-reports.position.fields.BASE_PRICE' | transloco
          }}"
                  formControlName="basePrice"
                  type="number"
                  />
                </mat-form-field>
                <mat-form-field>
                  <mat-label>{{
                    'limit-reports.position.fields.PEAK_PRICE' | transloco
                  }}</mat-label>
                  <input
                    matInput
          placeholder="{{
            'limit-reports.position.fields.PEAK_PRICE' | transloco
          }}"
                    formControlName="peakPrice"
                    type="number"
                    />
                  </mat-form-field>
                </div>
              </form>
            </cc-form-container>
